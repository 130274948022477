import { base } from "@theme-ui/presets"
import { merge } from "theme-ui"

// import "typeface-lora"
// import "typeface-playfair-display"
// import "typeface-pt-serif"
import "typeface-merriweather"
// import "typeface-open-sans"

export default merge(base, {
  fonts: {
    body: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    heading: `'Merriweather', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  },
  lineHeight: {
    body: "1.625",
    heading: "1.25",
  },
  fontWeight: {
    light: 300,
    body: 400,
    semibold: 600,
    heading: 700,
    bold: 700,
  },
  text: {
    caps: {
      fontSize: 2,
      textTransform: "uppercase",
      letterSpacing: "wider",
      fontWeight: "semibold",
    },
  },
  buttons: {
    primary: {
      background: "black",
      borderRadius: 0,
      cursor: "pointer",
    },
  },
  forms: {
    input: {
      border: "none",
      borderRadius: 0,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "black"
    },
    textarea: {
      border: "none",
      borderRadius: 0,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: "black",
      fontFamily: "body"
    }
  },
  styles: {
    root: {
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 3,
    },
  },
})
